import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Helmet from "react-helmet";
import Layout from "../components/layout";

const DestinationPage = ({
  data: {
    markdown: { frontmatter },
  },
}) => {
  const { t } = useTranslation();
  const { bokunId } = frontmatter;
  const bokunWidgetUrl =
    "https://widgets.bokun.io/online-sales/31bf3e11-eee9-4489-88d7-eafe2cf3069c/experience/" +
    bokunId +
    t("bokunWidgetUrlLang");

  return (
    <Layout headerWithBg>
      <Helmet>
        <script
          type="text/javascript"
          src="https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=31bf3e11-eee9-4489-88d7-eafe2cf3069c"
          async
        ></script>
      </Helmet>
      <div class="bokunWidget" data-src={bokunWidgetUrl}></div>
      <noscript>Please enable javascript in your browser to book</noscript>
    </Layout>
  );
};

export const query = graphql`
  query DestinationPageQuery($id: String!, $language: String!) {
    markdown: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        bokunId
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DestinationPage;
